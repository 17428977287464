@import "./Font.scss";
@import "./Theme.scss";

body,
html {
  margin: 0;
  padding: 0;
  font-family: "f1";
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "f3";
}

p {
  font-size: 14px;
}

h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: $black;
}
:focus-visible {
  outline: 0 !important;
}
::placeholder {
  font-size: 13px;
}
ul {
  list-style: none;
  padding: 0;
}

.err {
  color: #ff0000;
}

.bg-primar {
  background-color: $primary;
}

.primary {
  color: $primary;
}

.bg-primary1 {
  background-color: $primary1;
}

.primary1 {
  color: $primary1;
}
.bg-gradient {
  background: linear-gradient($primary, $primary1) !important;
}

.lightBlue {
  color: $lightBlue;
}

// naren start
.bg-darkBlue {
  background-color: $Blue !important;
}

.login-disable-btn {
  background-color: $Blue !important;
  opacity: 50%;
}
// naren end
.bg-lightBlue {
  background-color: $lightBlue;
}

.bg-lightBlue1 {
  background-color: $lightBlue1;
}

.lightBlue1 {
  color: $lightBlue1;
}

.bg-gray {
  background-color: $gray;
}

.bg-orange {
  background-color: $orange;
}
.orange {
  color: $orange;
}

.gray {
  color: $gray;
}

.bg-gray1 {
  background-color: $gray1;
}

.gray1 {
  color: $gray1;
}

.w-95 {
  width: 95%;
  margin: auto;
}

.w-90 {
  width: 90%;
  margin: auto;
}

.w-80 {
  width: 80%;
  margin: auto;
}

.w-70 {
  width: 70%;
  margin: auto;
}

.w-75 {
  width: 75%;
  margin: auto;
}

.w-60 {
  width: 60%;
  margin: auto;
}

.w-65 {
  width: 65%;
  margin: auto;
}

.w-40 {
  width: 40%;
  margin: auto;
}

.w-45 {
  width: 45%;
  margin: auto;
}

.h-100 {
  height: 100vh !important;
}
.min-h-100 {
  min-height: 100vh !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15;
}

.f1 {
  font-family: "f1";
}
.f2 {
  font-family: "f2";
}

.f3 {
  font-family: "f3";
}

.ac-jc {
  align-items: center;
  justify-content: center;
}
.ac-jb {
  align-items: center;
  justify-content: space-between;
}

.cust-btn {
  border: 0;
  background: transparent;
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5685e7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2f6df1;
}
