@import "./Custome.scss";
@import "./Font.scss";
@import "./Theme.scss";

.card_login {
  display: flex;
  flex-wrap: wrap;
  // margin-right: -14px;
  // margin-left: -14px;
}

.content {
  width: 100% !important;
  padding: 1rem 2rem 1px;
}

.main_container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 100%;
}

.hero-static {
  min-height: 100vh;
}

.form-groups {
  margin-bottom: 1.5rem;
}

.login_h5 {
  font-family: "f2";
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -14px;
  margin-left: -14px;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.bg-form {
  background: linear-gradient(to bottom right, #5d64c5, #852288);
  opacity: 0.9;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.btn-alt-light {
  color: #343a40;
  background-color: #eff1f4 !important;
  border-color: #eff1f4;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn {
  display: inline-block;
  font-weight: 600;
  color: #343a40;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-controls.form-control-alts {
  border-color: #edf0f2;
  background-color: #edf0f2;
  transition: none;
}

.form-control-lgs {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.form-controls {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d5dce1;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

//navbar-start
.nav-bar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1032;
  // width: 100%;
  width: 240px;
  height: 100%;
  // overflow-y: auto;
  // -webkit-transform: translateX(-100%) translateY(0) translateZ(0);
  // transform: translateX(-100%) translateY(0) translateZ(0);
  -webkit-overflow-scrolling: touch;
  will-change: transform;

  // width: 250px;
  // height: 100vh;
  // overflow-y: scroll;
  background: linear-gradient(-20deg, #5d64c5, #852288) !important;
  .menu-list {
    // width: 100%;
    width: 240px;
    margin: 0 auto;
    padding: 1.25rem 1.25rem 1px;
    // overflow-x: hidden;
    width: 100%;
    margin: 0 auto;
    padding: 1.25rem 1.25rem 1px;
    overflow-y: auto;
    // height: 100%;
    height: 90vh;

    .menu-list_main {
      padding-left: 0;
      list-style: none;
      margin-left: -1.25rem;
      margin-right: -1.25rem;
    }

    .nav-main-heading {
      color: #fff !important;
    }

    .nav-main-heading {
      padding: 1.375rem 1.25rem 0.375rem;
      font-family: "f1";
      font-size: 0.95rem !important;
      font-weight: 600 !important;
      font-weight: 0;
      text-transform: uppercase;
      letter-spacing: 0.0625rem;
      color: #6d7a86;
    }

    .nav-main-item {
      display: flex;
      flex-direction: column;
    }

    .toggle-nav-btn {
      color: hsla(0, 0%, 100%, 0.8) !important;
    }

    .toggle-nav-btn {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0.5rem 1.25rem;
      min-height: 2.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #343a40;
    }

    .menu_icon {
      color: #fff !important;
      .icon {
        font-size: 15px;
      }
    }

    .nav-main-link {
      color: hsla(0, 0%, 100%, 0.8) !important;
    }

    .nav-main-link {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0.5rem 1.25rem;
      min-height: 2.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #343a40;
      cursor: pointer;
    }

    .nav-main-link-active {
      background: #f8f9fa !important;
      color: #852288 !important;

      position: relative;
      display: flex;
      align-items: center;
      padding: 0.5rem 1.25rem;
      min-height: 2.5rem;
      font-size: 0.875rem;
      line-height: 1.25rem;
      cursor: pointer;

      .nav-main-link-icon {
        flex: 0 0 auto;
        display: inline-block;
        margin-right: 0.625rem;
        min-width: 1rem;
        text-align: center;
        background: #f8f9fa !important;
        color: #852288 !important;
        cursor: pointer;
      }
    }

    .nav-main-link-icon {
      color: #fff !important;
    }

    .nav-main-link-icon {
      flex: 0 0 auto;
      display: inline-block;
      margin-right: 0.625rem;
      min-width: 1rem;
      text-align: center;
      color: #88939e;
      cursor: pointer;
    }

    .menu_icon {
      flex: 0 0 auto;
      display: inline-block;
      margin-right: 0.625rem;
      min-width: 1rem;
      text-align: center;
      color: #88939e;
    }

    .nav-main-link-name {
      flex: 1 1 auto;
      display: inline-block;
      max-width: 100%;
    }

    // margin-left: 10px;
    // .toggle-nav-btn {
    //   display: flex;
    //   padding: 10px;
    //   width: 230px;
    //   text-align: left;
    // }
    // .toggle-nav-btn-active {
    //   display: flex;
    //   padding: 10px;
    //   background-color: #852288 !important;
    //   width: 230px;
    //   border-top-right-radius: 20px;
    //   border-bottom-right-radius: 20px;
    //   font-family: "f2";
    //   text-align: left;
    // }
    // .menu-icon {
    //   width: 21px;
    //   height: 21px;
    //   margin-left: 6px;
    //   margin-right: 10px;
    //   border-radius: 1px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   img {
    //     width: 13px;
    //     filter: invert(0);
    //   }
    // }
    // .menu-icon-active {
    //   width: 21px;
    //   height: 21px;
    //   margin-left: 6px;
    //   margin-right: 10px;
    //   border-radius: 2px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   img {
    //     width: 13px;
    //     filter: invert(1);
    //   }
    //   background-color: $primary;
    // }
  }
}

.top-navbar {
  padding-top: 10px;
  img {
    width: 60px;
    background-color: #fff;
    border-radius: 10px;
  }
  h5 {
    font-family: "f3";
    color: #fff;
    font-size: 15px;
    margin-top: 7px;
  }
}

.content-header {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 5rem;

  h5 {
    font-family: "f4";
  }
}
.bg-white-5 {
  background-color: hsla(0, 0%, 100%, 0.05) !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.nav-bar .menu-list .nav-main-link:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

// STAFF LAYOUT
.top-design-staff {
  top: 0;
  width: 100%;
}

.body-design {
  margin-top: 70px;
}

.page-header {
  position: relative;
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
}

.drop-btn {
  color: #fff;
  background-color: #727b84 !important;
  border-color: #6c757d !important;
  border: none;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;

  img {
    width: 21px;
    border-radius: 50%;
  }
}

.drop-btns {
  display: inline-block;
  font-weight: 600;
  color: #343a40;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.action_btn {
  margin-top: 5px;
  text-align: start;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 10%;
  position: fixed;
  // top: 105px !important;
  right: 20px !important;
  background: #fff;
  z-index: 10000 !important;
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 !important;
  }

  .logout {
    border-top: 1px solid #e1e6e9;

    p {
      font-size: 0.875rem !important;
    }
  }
}

.hover_eff:hover {
  background-color: #efefef !important;
  transition: 0.1s ease-in !important;
}

.dashBox {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(216, 223, 227, 0.5),
    0 1px 2px rgba(216, 223, 227, 0.5);
  border-radius: 2px;

  h5 {
    color: #6e6b6b;
    font-size: 16px;
  }

  h2 {
    color: #5593db;
    font-size: 28px;
    font-weight: normal;
  }
}

.ative-class {
  color: #000 !important;
  background-color: #d8d8d8;
}

.dash-icon svg {
  font-size: 25px;
  color: #d9c7ff;
}

.block-content {
  transition: opacity 0.25s ease-out;
  width: 100%;
  margin: 0 auto;
  padding: 1.25rem 1.25rem 1px;
  overflow-x: visible;

  table {
    border-collapse: collapse;
  }
}
.block-link-pop {
  &:hover {
    box-shadow: 0 0.5rem 2.5rem darken(#edf0f2, 6%);
    transform: translateY(-2px);
    opacity: 1;
  }

  &:active {
    box-shadow: 0 0.375rem 0.55rem darken(#edf0f2, 1%);
    transform: translateY(0);
  }
}

.block.block-rounded > .block-content:last-child {
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.block.block-rounded > .block-content:first-child {
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.bg-success-light {
  background-color: #bff0dd !important;
}

.bg-danger-light {
  background-color: #fbeaea !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.text-muted {
  color: #6c757d !important;
}

.dash_box {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(216, 223, 227, 0.5),
    0 1px 2px rgba(216, 223, 227, 0.5);
  border-radius: 20px;
}

.font-size-h3 {
  font-size: 1.5rem;
}

// TABLE DASHBOARD
.block.block-rounded {
  border-radius: 0.25rem;
}

.block {
  margin-bottom: 1.875rem;
  background-color: #fff !important;
  box-shadow: 0 1px 2px rgba(216, 223, 227, 0.5),
    0 1px 2px rgba(216, 223, 227, 0.5);
}

.bg-body-light {
  background-color: #f6f7f8 !important;
}

.content.content-full {
  padding-bottom: 1rem;
}

.app_h3 {
  font-size: 1.5rem;
  color: #373737;
  font-weight: 600;
  line-height: 1.25;
  font-family: "f2";
}

.navi-btn {
  button {
    border: none;
    background-color: transparent;
    font-size: 0.875rem;
    font-weight: 600;
    color: #5179d6;
    background-color: transparent;
    transition: color 0.12s ease-out;
  }

  svg {
    color: #ced4da;
  }

  .active {
    color: #6c757d;
  }
}

.nav-tabs .nav-link.active {
  background: linear-gradient(-20deg, #5d64c5, #852288) !important;
  color: #fff;
}

.bg-purple {
  background: #5d64c5 !important;
}

.generallinformation {
  .blocks.block-rounded {
    border-radius: 0.25rem;
  }
  .blocks {
    margin-bottom: 1.875rem;
    background-color: #fff;
  }

  .h5 {
    font-size: 1.125rem;
    font-family: "f5";
    color: #7a43e9;
    background: #5d64c6;
    padding: 0.75rem;
    font-weight: 600;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
  }

  .h5_2 {
    font-size: 1.125rem;
    font-family: "f5";
    color: #7a43e9;
    background: #5d64c6;
    padding: 0.75rem;
    font-weight: 600;
    font-size: 1.1rem;
    letter-spacing: 0.0625rem;
  }

  .h8 {
    font-size: 1.125rem;
    font-family: "f5";
    background: #f6f7f8;
    padding: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
    color: #7a43e9;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid #e1e6e9 !important;
  }

  .h8_2 {
    font-size: 1.125rem;
    font-family: "f5";
    background: #f6f7f8;
    padding: 0.75rem;
    color: #7a43e9;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.0625rem;
    border-bottom: 1px solid #e1e6e9 !important;
  }
}

.doc-img {
  height: 170px;
}

.doc-img {
  cursor: pointer;
  transition: 0.5s;
}

.tables {
  thead th {
    color: #7a43e9;
    background: #5d64c6;
    padding: 0.75rem;
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
  }

  tbody {
    border: none !important;
    td {
      padding: 0.75rem;
      vertical-align: top;
      // border-top: 1px solid #e1e6e9;
    }
  }
}
.tablelist {
  width: 100%;
  margin-bottom: 1rem;
  color: #343a40;
  background-color: transparent;
}

.appli-btn {
  color: #fff;
  background-color: #5179d6;
  border-color: #5179d6;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-success {
  color: #fff;
  background-color: #30c78d;
  border-color: #30c78d;
}

.form-control_draw {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #343a40;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d5dce1;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: auto;
  resize: vertical;
}

.page-headers {
  padding-left: 240px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  min-width: 320px;
  max-width: 100%;
  width: auto;
  box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.02);
}

// FONT WEIGHTS
.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.font-bold {
  font-weight: bold;
}

.font-bolder {
  font-weight: bolder;
}

// INPUT BOXES

.user-frm {
  .form-group {
    margin-bottom: 1.5rem;
  }
  .add-admin-frm {
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .form-btn {
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }

  .form-btn button {
    border: none;
    background: #7b44eb !important;
    padding: 10px 30px;
    color: #fff;
    border-radius: 5px;
    outline: none;
  }

  .btn-secondary {
    color: #fff;
    background-color: #727b84 !important;
    border-color: #6c757d !important;
  }

  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268 !important;
    border-color: #545b62 !important;
  }
  .form-btn button:hover {
    color: #fff;
    background-color: #5a6268 !important;
    border-color: #545b62 !important;
  }
}

.place-btn {
  color: #2a3f6f !important;
  background-color: #ced9f4 !important;
  display: block;
  padding: 0.75rem 1.25rem;
  border: 1px solid #e1e6e9;
  border-radius: 5px;
}

.place-list-active {
  display: block;
  padding: 0.95rem 1.5rem;
  z-index: 2;
  color: #fff;
  background-color: #5179d6;
  border-color: #5179d6;
}

.place-list {
  display: block;
  padding: 0.95rem 1.5rem;
  background-color: #fff;
  border: 1px solid #e1e6e9;
}

.place-flot {
  float: right !important;
  border-radius: 10px;
}

.place-icon {
  border: none;
  color: #25499c;
  background-color: #d9e2f6;
  border-color: #d9e2f6;
}

.place-icon:hover {
  color: #25499c;
  background-color: #bacaef;
  border-color: #bacaef;
}

.err {
  position: absolute;
  font-size: 12px;
  display: flex;
  justify-content: end;
  right: 0;
}

.err2 {
  position: absolute;
  font-size: 12px;
  display: flex;
  justify-content: start;
  color: red;
}
