@import "./Custome.scss";
@import "./Font.scss";
@import "./Theme.scss";
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5685e7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2f6df1;
}

$bg-gradiant: linear-gradient(-20deg, #5d64c5, #852288);
$bg-pink: #852288;
$bg-purple: #5d64c5;
$bg-order: linear-gradient(to right, #24d5dc, #5685e7);
$bg-customer: linear-gradient(-20deg, #f75896, #fe727a);
$bg-vendor: linear-gradient(-20deg, #40d89e, #3dbfac);
$bg-wallet: linear-gradient(-20deg, #ffd542, #ff9b50);

li {
  list-style: none !important;
}

.login-form {
  box-shadow: 5px 5px 15px #00ffff, -5px -5px 15px #00ffff !important;
}

.bg-form {
  background: linear-gradient(
    to bottom right,
    rgb(93, 100, 197),
    rgb(133, 34, 136)
  );
  opacity: 0.9;
}

.title-text {
  background: linear-gradient(
    to bottom right,
    rgb(93, 100, 197),
    rgb(133, 34, 136)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.theme-bg {
  background: $bg-gradiant;
}

.bg-pink {
  background: $bg-pink;
}

.bg-primary-dark {
  background: $bg-gradiant !important;
}

.bg-purple {
  background: $bg-purple !important;
}

/* -------------------------------------------------- */
/*  Customized Bootstrap Button */
/* -------------------------------------------------- */

.btn-secondary {
  color: #fff;
  background-color: #727b84 !important;
  border-color: #6c757d !important;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268 !important;
  border-color: #545b62 !important;
}

.btn-danger {
  background-color: #ff586b !important;
  color: #fff !important;
  border-color: #ff586b;
}

.btn-danger:hover {
  background-color: #f84c60 !important;
  color: #fff !important;
  border-color: #f84c60;
}

.custom-select {
  border-color: #edf0f2 !important;
  background-color: #edf0f2 !important;
  transition: none;
}

/* -------------------------------------------------- */
/*  Tables */
/* -------------------------------------------------- */

.table thead th {
  border-bottom: 1px solid #e1e6e9 !important;
}

.small-table-view {
  width: 1320px !important;
  overflow-x: scroll;
}

.common-table-view {
  width: 1320px !important;
  overflow-x: scroll;
}

.medium-table-view {
  width: 1800px !important;
  overflow-x: scroll;
}

.large-table-view {
  width: 2000px !important;
  overflow-x: scroll;
}

/* -------------------------------------------------- */
/*  Customized Tables */
/* -------------------------------------------------- */

.table-list {
  width: 100%;
  margin-bottom: 1rem;
  color: #343a40;
  background-color: white !important;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 25%) !important;
  border-radius: 0.5rem !important;
}

.table-list th,
.table-list td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0px solid #e1e6e9 !important;
}

.table-list thead {
  background: #8836da !important;
  color: #fff !important;
}

.table-list thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #e1e6e9 !important;
}

.table-list td {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #e1e6e9 !important;
  cursor: pointer !important;
}

/* -------------------------------------------------- */
/* Common */
/* -------------------------------------------------- */

.cursor-pointer {
  cursor: pointer !important;
}

/* -------------------------------------------------- */
/* Sidenav */
/* -------------------------------------------------- */
.sidebar {
  background: $bg-gradiant !important;
}

.logo-bg {
  background: $bg-gradiant !important;
}

.sidebar-dark .sidebar .nav-main-link,
.page-header-dark .page-header .nav-main-link {
  color: rgba(255, 255, 255, 0.8) !important;
}

.sidebar-dark .sidebar .nav-main-link > .nav-main-link-icon,
.page-header-dark .page-header .nav-main-link > .nav-main-link-icon {
  color: #fff;
}

.nav-tabs .nav-link.active {
  background: $bg-gradiant !important;
  color: #fff;
}

.sidebar-dark .sidebar .nav-main-link.active {
  background: #f8f9fa !important;
  color: $bg-pink !important;
}

.nav-main-heading {
  padding: 1.375rem 1.25rem 0.375rem;
  font-size: 0.95rem !important;
  font-weight: 600 !important;
  font-weight: 0;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  color: #6d7a86;
}

.sidebar-dark .sidebar .nav-main-link.active > .nav-main-link-icon,
.page-header-dark .page-header .nav-main-link.active > .nav-main-link-icon {
  color: $bg-pink;
}

/* -------------------------------------------------- */
/* Dashboard */
/* -------------------------------------------------- */

.dashboard-card {
  transition: 0.5s;
  cursor: pointer;
}

.dashboard-card:hover {
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  opacity: 1;
}

.order {
  background: $bg-order;
}
.customer {
  background: $bg-customer;
}
.vendor {
  background: $bg-vendor;
}
.wallet {
  background: $bg-wallet;
}

.card-icon {
  font-size: 2rem !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #efefef !important;
}

.table-hover tbody tr:hover {
  color: #343a40;
  background-color: #efefef !important;
}

/* -------------------------------------------------- */
/* Profile */
/* -------------------------------------------------- */

/*---------------------------*/

.cardCenter {
  align-items: center;
  justify-content: center;
  display: inline-flex;
  width: 100%;
  height: 93vh;
}

.svg-icon {
  width: 100px;
  height: 100px;
}

.bg {
  background: #fff;
  color: #000;
}

button:focus {
  outline: none !important;
  border: none;
}

button:active {
  outline: none !important;
  border: none;
}

.rela-block {
  display: block;
  position: relative;
  margin: auto;
}

.rela-inline {
  display: inline-block;
  position: relative;
  margin: auto;
}

.floated {
  display: inline-block;
  position: relative;
  margin: false;
  float: left;
}

.floated.right {
  float: right;
}

.abs-center {
  display: false;
  position: absolute;
  margin: false;
  top: 50%;
  left: 50%;
  right: false;
  bottom: false;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 88%;
}

.vert-center {
  display: false;
  position: absolute;
  margin-top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.horz-center {
  display: false;
  position: absolute;
  margin: false;
  top: false;
  left: 50%;
  right: false;
  bottom: false;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.blurred {
  -webkit-filter: blur(2px);
  filter: blur(2px);
}

.overlay {
  z-index: -1;
  display: false;
  position: fixed;
  margin: false;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  opacity: 0;
}

.overlay.active {
  z-index: 100;
  opacity: 1;
}

.overlay.active .overlay-image {
  opacity: 1;
}

.overlay-card {
  height: 75vh;
  min-height: 400px;
  width: calc(92% - 40px);
  max-width: 1086px;
  padding-left: 30px;
  background-color: #f4f4f4;
  border-radius: 2px;
  overflow: hidden;
}

.overlay-image {
  height: 100%;
  width: 480px;
  opacity: 0;
  -webkit-transition: 0.3s cubic-bezier(0.75, 0, 0.2, 1), opacity 0.2s 0.3s ease;
  transition: 0.3s cubic-bezier(0.75, 0, 0.2, 1), opacity 0.2s 0.3s ease;
}

.overlay-desc {
  font-family: "Roboto";
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 400;
  line-height: 20px;
  height: calc(100% - 40px);
  margin: 20px;
  border-left: 1px solid #ccc;
  width: calc(100% - 520px);
  padding: 20px 20px 20px 40px;
  overflow-x: hidden;
  overflow-y: auto;
}

.overlay-desc::-webkit-scrollbar {
  width: 6px;
}

.overlay-desc::-webkit-scrollbar-thumb {
  background-color: #999;
}

.post-image {
  height: 100%;
  max-height: 400px;
  width: 480px;
  border: 20px solid #fff;
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.25);
  background-size: cover !important;
  cursor: pointer;
}

.desc-title {
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 5px;
}

.desc-author {
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 400;
  line-height: 24px;
  color: #999;
  margin-bottom: 10px;
}

.profile-card {
  width: calc(100% - 40px);
  padding-top: 100px;
  margin: 70px auto 30px;
  background-color: #fff;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.26);
}

.profile-pic {
  display: false;
  position: absolute;
  margin: false;
  top: -90px;
  left: 50%;
  right: false;
  bottom: false;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 180px;
  width: 180px;
  border: 10px solid #fff;
  border-radius: 100%;
  background: url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEBLAEsAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/wAALCACAAIABAREA/8QAGgABAQEBAQEBAAAAAAAAAAAAAAYFBAMCCf/EADgQAAIBAgEIBwMNAAAAAAAAAAABAgMEBQYREhQhIjFBUVRhcYGSsUKC8RMyMzVEUpGhwcLR4fD/2gAIAQEAAD8A/TgAAAAAAAAAAAAAAAAAAAAAAAAAAGTiuPwsZOlSSq1lxz8I95gVcZva8tteceyG76CljN7Qlsrzl2T3vU38Kx+F9JUqqVKs+GbhLuNYAAAAA4cYvnYWM5x+klux72TOE4bLFLlqTapx2zlz+JXW1pRtIaNGnGC7FtfiLm0o3cNGtTjNdq2rxJHFsNlhdylFt05bYS5/EpsHvnf2MJy+kjuy70dwAAAAJ/K2TULWPJuT9P5OjJemo4fKXOU3nfgjYBj5UU1LD4y5xmsz8Gc+SUm4XUeScX6/wUAAAAAJ7K37J7/7TryZ+rPff6GsDJym+rPfX6nJkl9r9z9xQgAAAAw8qqLnbUaqWyEmn4/A+clbqLpVbdveT00ulc/92m8DByquoqlSt095vTa6Fy/3YfWStFwtq1VrZOSS8PibgAAAAOW/nbSt50rirCEZLNvSSZG6crK60qNVNwe7UjzNy2yrjoJXFGWl96nz8GLnKuOg1b0ZaX3qnLwRh6cr260q1VJze9UlyLKwnbRt4UrerCcYrNuyTZ1AAAA48RxWjhsM83pVH82C4smrzHru7bSn8lB+zT2fmedDB7263o0ZJP2p7PU7aeStxJb9WnDuzs91kl03X4U/7DyS6Lr8af8AZ4VMlbiK3KtOffnRxV8HvbXelRk0vaht9D0s8eu7RpOfy0F7NTb+ZS4dilHEoNw3Zr50HxR2AAAhpSqYriG179WeZZ+SKywwi3sIpxip1OdSS2+HQdoAAOG/wi3v4vSioVOVSK2+PSSsJVMKxDY9+lPM83BouAAAQ2GzVHEaDm9FRms7fIueIAAA4ENiU1WxGu4PSUpvM1zLkAAE3jOAVXXlXto6cZvPKC4p9hlaherZq9fySGo3vV7jySGo3vV7jySGo3vV7jySGo3vV7jySGo3vV7jySGo3vV7jySGoXr2avX8kjVwbAKqrxr3MdCMHnjB8W+0pAAAAAAAAAAAAAAAAAAAAAAAAAD/2Q==")
    center no-repeat;
  background-size: cover;
}

.profile-name-container {
  margin: 0 auto 10px;
  padding: 10px;
  text-align: center;
}

.user-name {
  font-size: 24px;
  letter-spacing: 3px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 12px;
}

.user-desc {
  letter-spacing: 1px;
  color: #999;
}

.profile-card-stats {
  height: 75px;
  padding: 10px 0px;
  text-align: center;
  overflow: hidden;
}

.profile-stat {
  height: 100%;
  width: 33.3333%;
}

.profile-stat:after {
  color: #999;
}

.works::after {
  // content: "works";
}

.followers::after {
  // content: "followers";
}

.following::after {
  // content: "following";
}

.image {
  width: 240px;
  height: 200px;
  cursor: pointer;
  margin: 0 20px 40px;
  overflow: hidden;
  border-radius: 5px;
  border: 10px solid #fff;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.4);
  background-size: cover !important;
  -webkit-transition: 0.2s cubic-bezier(0.5, 0, 0.2, 1);
  transition: 0.2s cubic-bezier(0.5, 0, 0.2, 1);
}

.image:hover {
  -webkit-transform: scale(1.06);
  transform: scale(1.06);
  box-shadow: 0 2px 18px -2px rgba(0, 0, 0, 0.3);
}

.image.hidden {
  height: 0;
  width: 0px;
  margin: 0px;
  border: 0px solid #fff;
}

/* //  **********   MEDIA QUERY FOR ALL CLASS  *************** / */

@media screen and (max-width: 550px) {
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 2px;
    padding-left: 2px;
  }

  .container-fluid {
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: auto;
    margin-left: auto;
  }

  .p-5 {
    padding: 1.2em !important;
  }

  .overlay-card {
    height: 500px;
  }
  .post-image {
    width: 80%;
    max-width: 288px;
    height: 240px;
  }
  .overlay-desc {
    padding: 14px;
  }
  .profile-card-stats {
    height: 0;
    padding: 0px;
  }
  .profile-pic {
    height: 140px;
    width: 140px;
    top: -70px;
  }
  .profile-card {
    padding-top: 70px;
    margin: 50px auto 30px;
  }
}

.btn-setting {
  cursor: pointer;
}

.fa {
  font-size: 1em;
}

.btn-outline-danger:hover {
  .btn-setting {
    animation: spin 3s linear infinite;
    -webkit-animation: spin 3s linear infinite;
  }
}

.btn:active {
  outline: none !important;
}

.btn:focus {
  outline: none !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(360deg);
  }
}

/* -------------------------------------------------- */
/* SMTP */
/* -------------------------------------------------- */

.status input[type="checkbox"] {
  visibility: hidden;
  &:checked + label {
    background-color: #f5f5f5;
    border: 3px solid #198754;
    &:before {
      transform: translateX(35px);
      background-color: #198754;
    }
  }
}

.label {
  display: flex;
  width: 70px;
  height: 35px;
  border: 3px solid #dc3545;
  border-radius: 33em;
  background-color: #f5f5f5;
  position: relative;
  transition: transform 0.3s ease-in-out;
  transform-origin: 50% 50%;
  cursor: pointer;

  &:before {
    transition: transform 0.3s ease;
    transition-delay: 0.3s;
    content: "";
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: #dc3545;
    border-radius: 50%;
    top: 3px;
    left: 3px;
  }
}

.status-btn input[type="checkbox"] {
  visibility: hidden;
  &:checked + label {
    background-color: #f5f5f5;
    border: 3px solid #198754;
    &:before {
      transform: translateX(35px);
      background-color: #198754;
    }
  }
}

.label {
  display: block;
  width: 70px;
  height: 35px;
  border: 3px solid #dc3545;
  border-radius: 33em;
  background-color: #f5f5f5;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease-in-out;
  transform-origin: 50% 50%;
  cursor: pointer;

  &:before {
    transition: transform 0.3s ease;
    transition-delay: 0.3s;
    content: "";
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: #dc3545;
    border-radius: 50%;
    top: 3px;
    left: 3px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 60px !important;
  height: 30px !important;
  padding: 3px;
  background-color: white;
  border-radius: 18px;
  cursor: pointer;
  // background-image: -webkit-linear-gradient(top, #eeeeee, white 25px);
  // background-image: -moz-linear-gradient(top, #eeeeee, white 25px);
  // background-image: -o-linear-gradient(top, #eeeeee, white 25px);
  // background-image: linear-gradient(to bottom, #eeeeee, white 25px);
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #f02424;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12),
    inset 0 0 2px rgba(0, 0, 0, 0.12);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}
.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}
.switch-label:before {
  // content: attr(data-off);
  right: 11px;
  color: #fff;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
  // content: attr(data-on);
  left: 11px;
  color: white;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
  background: #47a8d8;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 30px;
  // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  // background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
  // background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
  // background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
  // background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: transparent;
  // border-radius: 6px;
  // box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  // background-image: -webkit-linear-gradient(top, #eeeeee, white);
  // background-image: -moz-linear-gradient(top, #eeeeee, white);
  // background-image: -o-linear-gradient(top, #eeeeee, white);
  // background-image: linear-gradient(to bottom, #eeeeee, white);
}
.switch-input:checked ~ .switch-handle {
  left: 30px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-green > .switch-input:checked ~ .switch-label {
  background: #4fb845;
}

.p-viewer,
.p-viewer2 {
  float: right;
  margin-top: -35px;
  margin-right: 15px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.fa-eye {
  color: #000;
}

.item-table {
  width: 1525px !important;
  overflow: scroll !important;
}

/* -------------------------------------------------- */
/* NAV TABS - Attribute Group Module */
/* -------------------------------------------------- */

.nav-tabs .nav-link:hover {
  border: 0px solid $bg-purple;
}

.nav-tabs .nav-link {
  border: 0px solid $bg-purple !important;
  background: #f5f5f5;
  margin-bottom: 0.5rem;
}

.nav-tabs {
  border-bottom: 0px solid $bg-purple !important;
}

.nav-tabs .nav-link.active {
  background: $bg-gradiant !important;
  color: #fff;
}

/* -------------------------------------------------- */
/* GALLERY IMAGE */
/* -------------------------------------------------- */

.light-gallery__next,
.light-gallery__prev[data-v-2951e615],
.light-gallery__close[data-v-2951e615] {
  position: absolute;
  z-index: 9999 !important;
  display: block !important;
  background: transparent;
  border: 0;
  line-height: 0;
  outline: none;
  padding: 7px;
  cursor: pointer;
}

.light-gallery__modal[data-v-2951e615] {
  position: fixed;
  display: block;
  z-index: 9999 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2) !important;
}

.doc-img {
  cursor: pointer;
  transition: 0.5s;
}

.doc-img:hover {
  transform: scale(1.1);
}

.doc-img {
  height: 170px;
}

@media (min-width: 1601px) {
  .small-table-view {
    width: 100% !important;
    overflow-x: scroll;
  }
}
#page-container.sidebar-o {
  padding-left: 0 !important;
}
.line-h1-36 {
  width: 100%;
  height: 1px;
  background: #c5c5c5;
}
