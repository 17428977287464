/* -------------------------------------------------- */
/* Responsive */
/* -------------------------------------------------- */
@import "./Custome.scss";
@import "./Font.scss";
@import "./theme.scss";
@media (min-width: 992px) {
  .nav-bar {
    width: 240px;
  }
  // .menu-list {
  //   width: 240px;
  // }
  .w-md-150 {
    width: 150px !important;
    .form-check-input {
      width: 150px;
      text-align: center;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .demand-total-text {
    font-weight: bold;
    color: #363636;
  }
  .tl-input input {
    font-weight: bold;
    color: #363636;
  }
}
@media (max-width: 1440px) {
  .small-table-view {
    width: 1170px !important;
    overflow-x: scroll;
  }
}

@media (max-width: 1200px) {
  .small-table-view {
    width: 1100px !important;
    overflow-x: scroll;
  }
}

@media (max-width: 1023px) {
  .small-table-view {
    width: 1020px !important;
    overflow-x: scroll;
  }
}

@media (max-width: 767px) {
  .small-table-view {
    width: 900px !important;
    overflow-x: scroll;
  }
}

@media (max-width: 768px) {
  .content {
    width: 100%;
    margin: 0 auto;
    padding: 1.875rem 1.875rem 1px;
    overflow-x: visible;
  }

  .content-full {
    padding-bottom: 1.875rem;
  }
}

@media (max-width: 576px) {
  .small-table-view {
    width: 800px !important;
    overflow-x: scroll;
  }
}

@media (max-width: 374px) {
  .small-table-view {
    width: 600px !important;
    overflow-x: scroll;
  }
}
